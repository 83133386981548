import ExifReader from "exifreader";

async function getImageDimensions(file: File) {
  const myImage = new Image();
  myImage.src = URL.createObjectURL(file);

  await new Promise((resolve) => (myImage.onload = resolve));

  return {
    Height: myImage.height,
    Width: myImage.width,
  };
}

export async function getFileMeta(file: File) {
  const {
    Make,
    Model,
    ApertureValue,
    FocalLength,
    ShutterSpeedValue,
    FileType,
  } = await ExifReader.load(file);

  return {
    Make: Make?.description,
    Model: Model?.description,
    ApertureValue:
      ApertureValue?.description && `ƒ/${ApertureValue?.description}`,
    FocalLength: FocalLength?.description,
    ShutterSpeed: ShutterSpeedValue?.description,
    FileType: FileType?.description,
    ...(await getImageDimensions(file)),
  };
}
