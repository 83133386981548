import { Suspense, useCallback, useMemo, useState } from "react";
import { Link, generatePath, useNavigate, useParams } from "react-router-dom";
import { QUERY_GROUP_BY_ID } from "queries/queryGroupById";
import { QUERY_GROUP_POSTS_BY_CATEGORY_IDS } from "queries/queryGroupPostsByCategoryIds";
import { useQuery, useSuspenseQuery } from "@apollo/client";
import { Hero } from "ui/Hero";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import HeartIcon from "@heroicons/react/16/solid/HeartIcon";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { RouteDefinition } from "util/routes";
import { ErrorBoundary } from "react-error-boundary";
import { MissingGroup } from "../ui/MissingGroup";
import { Container } from "ui/Container";
import { Box, Button, Flex, Heading, Spinner, Text } from "@radix-ui/themes";
import { twSize } from "util/twSize";
import styled from "styled-components";
import { PostImage } from "ui/PostImage";
import { SelectedPostOutlet } from "./SelectedPostOutlet";
import { isDefined } from "../utils/isDefined";
import groupBy from "lodash.groupby";

export function GroupPage() {
  const { groupId, postId } = useParams();
  const navigation = useNavigate();
  const [showPostAsModal, setShowPostAsModal] = useState(false);

  const handleSetSelectedPost = useCallback(
    (postId: string) => {
      if (!groupId) throw new Error("Group ID not found");

      setShowPostAsModal(true);

      navigation(
        generatePath(RouteDefinition.GROUP_POST_BY_ID, { postId, groupId })
      );
    },
    [groupId, navigation]
  );

  const showGroup = !(!!postId && !showPostAsModal);

  return (
    <ErrorBoundary fallback={<MissingGroup />}>
      {showGroup && (
        <Suspense fallback={<GroupPageLoading />}>
          <Group setSelectedPostId={handleSetSelectedPost} />
        </Suspense>
      )}

      <Suspense>
        <SelectedPostOutlet asModal={showPostAsModal} />
      </Suspense>
    </ErrorBoundary>
  );
}

function GroupPageLoading() {
  return (
    <Flex height="100%" width="100%" justify="center" align="center">
      <Spinner size="3" />
    </Flex>
  );
}

function groupPostsByDate<T extends { created_at: string }>(posts?: T[]) {
  return groupBy(posts, (post) =>
    new Date(new Date(post.created_at).toDateString()).valueOf()
  );
}

function useGroupById(groupId?: string, filteredCategoryIds?: string[]) {
  const { data } = useSuspenseQuery(QUERY_GROUP_BY_ID, {
    variables: {
      id: groupId,
      withPosts: true,
      withCategories: true,
    },
  });

  const { data: filteredCategoriesData, loading: filteredCategoriesLoading } =
    useQuery(QUERY_GROUP_POSTS_BY_CATEGORY_IDS, {
      variables: { id: groupId, filteredCategoryIds },
      skip: !filteredCategoryIds?.length,
    });

  return useMemo(() => {
    const group = data.groupCollection?.edges[0]?.node;

    const groupedPosts = groupPostsByDate(
      !!filteredCategoryIds?.length
        ? filteredCategoriesData?.filteredPosts?.edges.map((postEdge) => {
            return postEdge.node;
          })
        : group?.postCollection?.edges.map((postEdge) => postEdge.node)
    );

    return {
      data,
      group,
      groupedPosts,
      loading: filteredCategoriesLoading,
      categories: group?.group_categoryCollection?.edges
        .map((categoryEdge) => categoryEdge.node.category)
        .filter(isDefined),
    };
  }, [
    data,
    filteredCategoriesData?.filteredPosts?.edges,
    filteredCategoriesLoading,
    filteredCategoryIds?.length,
  ]);
}

const MOCK_FILTERED_CATEGORIES: string[] = [];

interface GroupsProps {
  setSelectedPostId(postId: string): void;
}

function Group({ setSelectedPostId }: GroupsProps) {
  const { groupId } = useParams();
  const { session } = useAuth();

  const { group, groupedPosts, loading } = useGroupById(
    groupId,
    MOCK_FILTERED_CATEGORIES
  );

  if (!group) throw new Error("Group not found");

  const newPostPath = generatePath(RouteDefinition.GROUP_NEW_POST, {
    groupId: group.id,
  });

  return (
    <Container>
      <Flex direction="column" gap={{ sm: "6" }}>
        <div>
          <Flex
            direction={{ initial: "column-reverse", sm: "row" }}
            align={{ sm: "center" }}
            gap={{ sm: "6" }}
          >
            <Box flexGrow="1">
              <Hero title={group?.display_name}>
                <Flex direction="column" gap="4">
                  <Text as="p">{group.description}</Text>

                  <Box>
                    <Button asChild size="3">
                      <Link
                        to={{
                          pathname: session?.user.id
                            ? newPostPath
                            : generatePath(RouteDefinition.AUTH_REGISTER),
                          search: !session?.user.id
                            ? `?redirectTo=${newPostPath}`
                            : "",
                        }}
                      >
                        <PlusIcon width={twSize(5)} height={twSize(5)} />

                        {session?.user.id ? "New Post" : "Sign in to post"}
                      </Link>
                    </Button>
                  </Box>
                </Flex>
              </Hero>
            </Box>

            <Flex align="center" justify="center" flexShrink="0">
              <Flex
                align="center"
                justify="center"
                width="320px"
                height="250px"
                style={{ backgroundColor: "var(--gray-3)" }}
              >
                Advert
              </Flex>
            </Flex>
          </Flex>
        </div>

        <Flex direction="column" gap="5">
          {loading ? (
            <Flex justify="center" py="4">
              <Spinner size="3" />
            </Flex>
          ) : !!Object.keys(groupedPosts).length ? (
            Object.entries(groupedPosts)
              .sort(([dateA], [dateB]) => +dateB - +dateA)
              .map(([date, posts]) => (
                <Flex key={date} direction="column" gap="2">
                  <Heading as="h3" style={{ color: "var(--gray-7)" }}>
                    {new Date(Number(date)).toDateString()}
                  </Heading>
                  <Posts
                    postIds={posts.map((post) => post.id)}
                    onClick={setSelectedPostId}
                  />
                </Flex>
              ))
          ) : (
            <div>No posts</div>
          )}
        </Flex>
      </Flex>
    </Container>
  );
}

interface PostsProps {
  onClick: (postId: string) => void;
  postIds: string[];
}

function Posts({ postIds, onClick }: PostsProps) {
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 750: 3 }}>
      <Masonry gutter="12px">
        {postIds?.map((postId) => (
          <Post key={postId} postId={postId} onClick={onClick} />
        ))}
      </Masonry>
    </ResponsiveMasonry>
  );
}

interface PostProps {
  postId: string;
  onClick: (postId: string) => void;
}

function Post({ postId, onClick }: PostProps) {
  const [isLoved, setIsLoved] = useState(false);

  const handleClick = useCallback(() => onClick(postId), [onClick, postId]);

  return (
    <Flex direction="column" gap="2">
      <Box position="relative">
        <PostImage postId={postId} />

        <PostSelectButton onClick={handleClick} />

        <LoveAction $isLoved={isLoved} onClick={() => setIsLoved((v) => !v)}>
          <Box height={twSize(4)} width={twSize(4)} asChild>
            <HeartIcon />
          </Box>
        </LoveAction>
      </Box>
    </Flex>
  );
}

const PostSelectButton = styled("button")`
  position: absolute;
  inset: 0;
  border: none;
  background: none;
  cursor: zoom-in;
`;

const LoveAction = styled(Button)<{ $isLoved: boolean }>`
  position: absolute;
  width: var(--space-5);
  height: var(--space-5);
  bottom: var(--space-2);
  right: var(--space-2);
  padding: var(--space-1);
  border-radius: var(--radius-2);

  ${({ $isLoved }) =>
    $isLoved
      ? `
    color: var(--pink-9);
    background-color: var(--pink-2);
  `
      : `
    color: var(--slate-9); 
    background-color: var(--gray-2);
  `}
`;
