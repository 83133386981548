import { graphql } from "gql";

export const GET_ALL_GROUPS = graphql(/* GraphQL */ `
  query AllGroups {
    groupCollection {
      edges {
        node {
          nodeId
          id
          display_name
          is_private
          postCollection(
            first: 2
            filter: { has_uploaded_photo: { eq: true } }
          ) {
            edges {
              node {
                nodeId
                id
              }
            }
          }
        }
      }
    }
  }
`);
