import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home";
import { PrimaryNavigation } from "./ui/PrimaryNavigation";

import { FourOhFourPage } from "./pages/FourOhFour";
import { Footer } from "./ui/Footer/Footer";
import { Auth } from "pages/Auth";
import { GroupsPage } from "pages/Groups";
import { GroupPage } from "pages/Group/GroupPage";
import { NewPostPage } from "pages/Group/NewPostPage";
import { RouteDefinition } from "util/routes";

import { Box, Flex, Section } from "@radix-ui/themes";
import { PostPage } from "pages/PostPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicAppLayout />}>
          <Route path={RouteDefinition.HOME} element={<HomePage />} />

          <Route path={RouteDefinition.GROUPS} element={<GroupsPage />} />
          <Route path={RouteDefinition.GROUP_BY_ID} element={<GroupPage />}>
            <Route
              path={RouteDefinition.GROUP_POST_BY_ID}
              element={<PostPage />}
            />
          </Route>

          <Route
            path={RouteDefinition.GROUP_NEW_POST}
            element={<NewPostPage />}
          />

          <Route
            path={RouteDefinition.AUTH_LOGIN}
            element={<Auth state="LOGIN" />}
          />
          <Route
            path={RouteDefinition.AUTH_REGISTER}
            element={<Auth state="SIGN_UP" />}
          />
          <Route path="*" element={<FourOhFourPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function PublicAppLayout() {
  return (
    <Flex direction="column" height="100%" justify="between">
      <Box flexShrink="0">
        <PrimaryNavigation />
      </Box>

      <Flex flexGrow="1">
        <Outlet />
      </Flex>

      <Section
        p="5"
        style={{
          backgroundColor: "var(--gray-a2)",
        }}
      >
        <Footer />
      </Section>
    </Flex>
  );
}

export default App;
