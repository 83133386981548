import { Box, Button, Container, Flex, Heading, Text } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Bubble } from "ui/Bubble";
import { RouteDefinition } from "util/routes";

const COLOR = "mint";

export default function Page() {
  const navigate = useNavigate();

  return (
    <Hero>
      <BlurredWrapper>
        <Container maxWidth="700px">
          <Flex direction="column" gap="6">
            <Flex gap="4" direction="column">
              <Heading as="h1" color={COLOR} size="7">
                <Text
                  as="span"
                  style={{
                    background: `var(--${COLOR}-5)`,
                    padding: "var(--space-2) var(--space-4)",
                    borderRadius: "var(--space-4)",
                  }}
                >
                  VotoBubble
                </Text>
              </Heading>
              <Heading as="h1" color={COLOR} size="9">
                A new home for your photographs.
              </Heading>

              <Text as="p" size="6" color={COLOR}>
                We're trying to build something new. A new place to share
                photographs, memories & experiences with people you know & care
                about. We currently imagine this could be friends, family or
                your local community.
              </Text>
              <Text as="p" size="6" color={COLOR}>
                Photographs can be shared in private or public groups, allowing
                you to share memories{" "}
                <Text as="span" weight="bold">
                  with the people you choose
                </Text>
                , for example{" "}
                <Text as="span" style={{ fontStyle: "italic" }}>
                  "Glastonbury 2024 Oxylers Crew"
                </Text>
                . Public groups can be used to allow wider communities to show
                off what's great about their towns & cities.
              </Text>
              <Text as="p" size="6" color={COLOR}>
                At the moment we're not sure how this is going to look & we're
                currently testing how people like yourself might want to use it.
                If you're interested in being part of this journey, please
                create an account & contribute to a group or get in touch.
              </Text>
            </Flex>
            <Flex gap="4" wrap="wrap">
              <Button
                color="mint"
                variant="surface"
                size="4"
                onClick={() => navigate(RouteDefinition.AUTH_REGISTER)}
              >
                Create account
              </Button>
              <Button
                color="mint"
                variant="surface"
                size="4"
                onClick={() => navigate(RouteDefinition.GROUPS)}
              >
                Browse groups
              </Button>
            </Flex>
          </Flex>
        </Container>
      </BlurredWrapper>

      {[...new Array(3)].map((_, i) => {
        const dimension = getRandomNumber(250, 350);

        return (
          <Bubble
            key={i}
            $src={`https://picsum.photos/${dimension}/${dimension}`}
            $maxWidth="100px"
            $blurTime={`${getRandomNumber(5, 10)}s`}
            $sideTime={`${getRandomNumber(2, 4)}s`}
            $topTime={`${getRandomNumber(10, 25)}s`}
            $left={`${getRandomNumber(-20, 120)}%`}
            $top={`${getRandomNumber(5, 95)}%`}
            $scale={getRandomNumber(0.2, 0.8)}
          />
        );
      })}
    </Hero>
  );
}

function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const BlurredWrapper = styled(Box)`
  padding: var(--space-9) var(--space-2);
  height: 100%;
  z-index: 2;
  position: relative;
  width: 100%;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
`;

const Hero = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;

  background: var(--${COLOR}-2);
  background: linear-gradient(
    45deg,
    var(--${COLOR}-2) 0%,
    var(--${COLOR}-3) 100%
  );
  overflow: hidden;
`;
