import {
  Link as RadixLink,
  LinkProps as RadixLinkProps,
} from "@radix-ui/themes";
import { Link, LinkProps } from "react-router-dom";

interface RouterLinkProps extends RadixLinkProps {
  to: LinkProps["to"];
}

export function RouterLink({ to, children, ...props }: RouterLinkProps) {
  return (
    <RadixLink asChild {...props}>
      <Link to={to}>{children}</Link>
    </RadixLink>
  );
}
