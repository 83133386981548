import { Box } from "@radix-ui/themes";
import styled, { keyframes } from "styled-components";

const CircleImageInner = styled(Box)<{ $maxWidth: string; $src: string }>`
  background-image: url(${(props) => props.$src});

  width: ${(props) => props.$maxWidth};
  height: ${(props) => props.$maxWidth};
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  position: absolute;
`;

const animateBubble = keyframes`
0% { margin-top: 1000px; }
100% { margin-top: -100%; }
`;

const sideWays = keyframes`
0% { margin-left: 0px; }
100% { margin-left: 50px; }
`;

export const Bubble = styled(CircleImageInner)<{
  $topTime: string;
  $sideTime: string;
  $blurTime: string;
  $left: string;
  $top: string;
  $scale: number;
}>`
  -webkit-animation: ${animateBubble} ${(props) => props.$topTime} linear
      infinite,
    ${sideWays} ${(props) => props.$sideTime} ease-in-out infinite alternate;
  -moz-animation: ${animateBubble} ${(props) => props.$topTime} linear infinite,
    ${sideWays} ${(props) => props.$sideTime} ease-in-out infinite alternate;
  animation: ${animateBubble} ${(props) => props.$topTime} linear infinite,
    ${sideWays} ${(props) => props.$sideTime} ease-in-out infinite alternate;

  left: ${(props) => props.$left};
  top: ${(props) => props.$top};
`;
