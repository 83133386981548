import { Photo_Type } from "gql/graphql";
import { ZodIssue } from "zod";

export interface PostState {
  file?: File;
  width?: number;
  height?: number;
  description?: string;
  categoryIds?: string[];
  photoType?: Photo_Type;
  cameraMake?: string;
  cameraModel?: string;
  loading: boolean;
  validationIssues?: ZodIssue[];
  submissionIssue?: string;
  postId?: string;
}

export const initialState: PostState = {
  description: "",
  loading: false,
  photoType: Photo_Type.Digital,
};

type ACTION_SET_DESCRIPTION = {
  type: "SET_DESCRIPTION";
  payload: string;
};

type ACTION_SET_FILE = {
  type: "SET_FILE";
  payload: File;
};

type ACTION_CLEAR_FILE = {
  type: "CLEAR_FILE";
};

type ACTION_SET_FILE_META = {
  type: "SET_FILE_META";
  payload: { width: number; height: number };
};

type ACTION_SET_PHOTO_TYPE = {
  type: "SET_PHOTO_TYPE";
  payload: Photo_Type;
};

type ACTION_SET_CAMERA_MAKE = {
  type: "SET_CAMERA_MAKE";
  payload: string;
};

type ACTION_SET_CAMERA_MODEL = {
  type: "SET_CAMERA_MODEL";
  payload: string;
};

type ACTION_SET_VALIDATION_ISSUES = {
  type: "SET_VALIDATION_ISSUES";
  payload?: ZodIssue[];
};

type ACTION_SET_MUTATION_ISSUES = {
  type: "SET_MUTATION_ISSUES";
  payload?: string;
};

type ACTION_SET_LOADING = {
  type: "SET_LOADING";
  payload: boolean;
};

type ACTION_TOGGLE_CATEGORY = {
  type: "TOGGLE_CATEGORY";
  payload: string;
};

type ACTION_RESET_FORM = {
  type: "RESET_FORM";
};

type ACTION_SET_PHOTO_ID = {
  type: "SET_PHOTO_ID";
  payload: string;
};

type Action =
  | ACTION_SET_DESCRIPTION
  | ACTION_RESET_FORM
  | ACTION_SET_FILE
  | ACTION_CLEAR_FILE
  | ACTION_SET_PHOTO_TYPE
  | ACTION_SET_CAMERA_MAKE
  | ACTION_SET_CAMERA_MODEL
  | ACTION_SET_VALIDATION_ISSUES
  | ACTION_SET_LOADING
  | ACTION_SET_MUTATION_ISSUES
  | ACTION_SET_FILE_META
  | ACTION_TOGGLE_CATEGORY
  | ACTION_SET_PHOTO_ID;

export function formReducer(state: PostState, action: Action) {
  switch (action.type) {
    case "SET_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };

    case "RESET_FORM":
      return initialState;

    case "SET_FILE":
      return {
        ...state,
        file: action.payload,
      };

    case "CLEAR_FILE": {
      return { ...state, file: initialState.file };
    }

    case "SET_FILE_META":
      return {
        ...state,
        width: action.payload.width,
        height: action.payload.height,
      };

    case "SET_PHOTO_TYPE":
      return {
        ...state,
        photoType: action.payload,
      };

    case "SET_CAMERA_MAKE": {
      return {
        ...state,
        cameraMake: action.payload,
      };
    }

    case "SET_CAMERA_MODEL": {
      return {
        ...state,
        cameraModel: action.payload,
      };
    }

    case "SET_VALIDATION_ISSUES": {
      return {
        ...state,
        validationIssues: action.payload,
      };
    }

    case "SET_LOADING": {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case "SET_MUTATION_ISSUES": {
      return {
        ...state,
        submissionIssue: action.payload,
      };
    }

    case "SET_PHOTO_ID": {
      return {
        ...state,
        postId: action.payload,
      };
    }

    case "TOGGLE_CATEGORY": {
      return {
        ...state,
        categoryIds: state.categoryIds?.includes(action.payload)
          ? state.categoryIds.filter((id) => id !== action.payload)
          : [...(state.categoryIds || []), action.payload],
      };
    }

    default:
      return state;
  }
}
