import { useCallback, useState } from "react";
import { RouteDefinition } from "util/routes";
import { ZodError, ZodIssue, z } from "zod";
import { Box, Flex, Text } from "@radix-ui/themes";
import { RouterLink } from "ui/RouterLink/RouterLink";
import { IssueList } from "ui/IssueList";
import styled from "styled-components";
import { getFileMeta } from "./getFileMeta";

const ACCEPTED_FILE_TYPES = ["image/jpg", "image/jpeg"];

const FileSchema = z
  .instanceof(File)
  .refine((file) => file instanceof File, { message: "Invalid file." })

  .refine((file) => !file || (!!file && file.type?.startsWith("image")), {
    message: "Only images are allowed to be sent.",
  })
  .refine((file) => ACCEPTED_FILE_TYPES.includes(file.type), {
    message: "File must be a JPG or JPEG.",
  });

const MetaSchema = z.object({ Height: z.number(), Width: z.number() });

async function parseFileUpload(file: unknown, fileSizeLimit: number) {
  FileSchema.refine((file) => !file || (!!file && file.size <= fileSizeLimit), {
    message: "The photo must be a maximum of 10MB.",
  });

  const validatedFile = FileSchema.parse(file);
  const validatedMeta = await MetaSchema.parseAsync(
    await getFileMeta(validatedFile)
  );

  return {
    file: validatedFile,
    meta: validatedMeta,
  };
}

export interface Upload {
  file: z.infer<typeof FileSchema>;
  meta: z.infer<typeof MetaSchema>;
}

interface SingleImageUploaderProps {
  onFileUpload: (upload: Upload) => void;
  fileSizeLimit: number;
}

export function SingleImageUploader({
  onFileUpload,
  fileSizeLimit,
}: SingleImageUploaderProps) {
  const [zodError, setZodError] = useState<ZodIssue[]>();
  const [isDragOver, setIsDragOver] = useState(false);

  const handleFileUpload = useCallback(
    async (file: File | null | undefined) => {
      try {
        const parsedFile = await parseFileUpload(file, fileSizeLimit);

        onFileUpload(parsedFile);
      } catch (error) {
        if (error instanceof ZodError) {
          setZodError(error.errors);
        }
      }
    },
    [fileSizeLimit, onFileUpload]
  );

  const handleDrop = useCallback(
    (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault();

      const { items, files } = ev.dataTransfer;

      if (items) {
        handleFileUpload(items[0].getAsFile());
      } else if (files) {
        handleFileUpload(files[0]);
      }
    },
    [handleFileUpload]
  );

  return (
    <Flex direction="column" gap="2">
      <StyledDropzone state={isDragOver ? "active" : "idle"}>
        <Text as="p">
          Drag and drop an image or{" "}
          <Text as="span" style={{ textDecoration: "underline" }}>
            Browse
          </Text>
        </Text>
        <input
          type="file"
          onChange={(ev) => handleFileUpload(ev.target.files?.[0])}
          onDrop={handleDrop}
          onDragEnter={() => setIsDragOver(true)}
          onDragLeave={() => setIsDragOver(false)}
        />
      </StyledDropzone>

      <Text as="p" size="1">
        Images must be no larger than {fileSizeLimit / 1024 / 1024}MB and comply
        with our{" "}
        <RouterLink to={RouteDefinition.TERMS_AND_CONDITIONS}>
          Terms & Conditions
        </RouterLink>
      </Text>

      {!!zodError?.length && <IssueList issueList={zodError} />}
    </Flex>
  );
}

const StyledDropzone = styled(Box)<{ state: "active" | "idle" }>`
  border: 1px solid var(--accent-a10);
  position: relative;
  padding: var(--space-4);
  border-radius: var(--radius-4);

  background-color: ${({ state }) =>
    state === "active" ? "var(--accent-a4)" : "var(--accent-a2)"};

  > p {
    color: var(--accent-a11);
    pointer-events: none;
  }

  > input {
    position: absolute;
    inset: 0;
    opacity: 0;
  }
`;
