import { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { createBrowserClient } from "util/supabase";
import { RouteDefinition } from "util/routes";

function useRedirectToSearchParam() {
  const [searchParams] = useSearchParams();

  return searchParams.get("redirectTo");
}

const FormSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
});

export function useSignUpForm() {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      setLoading(true);
      try {
        e.preventDefault();
        const supabase = createBrowserClient();

        const formData = new FormData(e.currentTarget);

        const data = FormSchema.parse({
          email: formData.get("email"),
          password: formData.get("password"),
        });

        await supabase.auth.signUp(data);

        setSubmitted(true);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    },
    []
  );

  return { handleSubmit, submitted, loading } as const;
}

export function useSignInForm() {
  const [loading, setLoading] = useState(false);
  const redirectTo = useRedirectToSearchParam();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      setLoading(true);
      try {
        e.preventDefault();
        const supabase = createBrowserClient();

        const formData = new FormData(e.currentTarget);

        const data = FormSchema.parse({
          email: formData.get("email"),
          password: formData.get("password"),
        });

        await supabase.auth.signInWithPassword(data);

        navigate(redirectTo ?? RouteDefinition.HOME);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    },
    [navigate, redirectTo]
  );

  return { handleSubmit, loading } as const;
}
