import { Box, Text } from "@radix-ui/themes";
import { ZodIssue } from "zod";

interface IssueListProps {
  issueList: ZodIssue[];
}

export function IssueList({ issueList }: IssueListProps) {
  return (
    <Box asChild m="0" p="0">
      <ul>
        {issueList.map((error) => (
          <Text key={error.message} size="2" color="red" asChild>
            <li>{error.message}</li>
          </Text>
        ))}
      </ul>
    </Box>
  );
}
