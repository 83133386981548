export enum RouteDefinition {
  HOME = "/",
  GROUPS = "/groups",

  GROUP_BY_ID = "/groups/:groupId",
  GROUP_NEW_POST = "/groups/:groupId/photograph/new",
  GROUP_POST_BY_ID = "/groups/:groupId/post/:postId",

  AUTH_REGISTER = "/auth/new",
  AUTH_LOGIN = "/auth/login",
  TERMS_AND_CONDITIONS = "/terms-and-conditions",
}
