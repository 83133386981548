import { graphql } from "gql";

export const QUERY_GROUP_POSTS_BY_CATEGORY_IDS = graphql(/* GraphQL */ `
  query GroupPostsByCategoryIds(
    $id: UUID!
    $filteredCategoryIds: [UUID!] = []
  ) {
    filteredPosts: groupPostsByCategoryIds(
      groupid: $id
      categoryids: $filteredCategoryIds
      orderBy: [{ created_at: DescNullsLast }]
    ) {
      edges {
        node {
          nodeId
          created_at
          id
        }
      }
    }
  }
`);
