import { Box, Flex, Heading } from "@radix-ui/themes";
import { PropsWithChildren } from "react";
import { twSize } from "util/twSize";

interface HeroProps extends PropsWithChildren {
  title: string;
}

export function Hero({ title, children }: HeroProps) {
  return (
    <Flex py="9" direction="column" gap="4">
      <Heading size="8" weight="bold">
        {title}
      </Heading>

      <Box>{children}</Box>
    </Flex>
  );
}
