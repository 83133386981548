/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query AllGroups {\n    groupCollection {\n      edges {\n        node {\n          nodeId\n          id\n          display_name\n          is_private\n          postCollection(\n            first: 2\n            filter: { has_uploaded_photo: { eq: true } }\n          ) {\n            edges {\n              node {\n                nodeId\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.AllGroupsDocument,
    "\n  query GroupById(\n    $id: UUID!\n    $withPosts: Boolean = false\n    $withCategories: Boolean = false\n  ) {\n    groupCollection(filter: { id: { eq: $id } }) {\n      edges {\n        node {\n          id\n          display_name\n          description\n          is_private\n          postCollection(\n            filter: { has_uploaded_photo: { eq: true } }\n            orderBy: [{ created_at: DescNullsLast }]\n          ) @include(if: $withPosts) {\n            edges {\n              node {\n                nodeId\n                created_at\n                id\n              }\n            }\n          }\n          group_categoryCollection @include(if: $withCategories) {\n            edges {\n              node {\n                nodeId\n                id\n                category {\n                  id\n                  display_name\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GroupByIdDocument,
    "\n  query GroupPostsByCategoryIds(\n    $id: UUID!\n    $filteredCategoryIds: [UUID!] = []\n  ) {\n    filteredPosts: groupPostsByCategoryIds(\n      groupid: $id\n      categoryids: $filteredCategoryIds\n      orderBy: [{ created_at: DescNullsLast }]\n    ) {\n      edges {\n        node {\n          nodeId\n          created_at\n          id\n        }\n      }\n    }\n  }\n": types.GroupPostsByCategoryIdsDocument,
    "\n  query PostById($id: UUID!, $withCategories: Boolean = false) {\n    postCollection(\n      filter: {\n        and: [{ id: { eq: $id } }, { has_uploaded_photo: { eq: true } }]\n      }\n    ) {\n      edges {\n        node {\n          nodeId\n          id\n          file_name\n          group_id\n          meta_width\n          meta_height\n          photo_type\n          description\n          post_categoryCollection @include(if: $withCategories) {\n            edges {\n              node {\n                id\n                category {\n                  display_name\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.PostByIdDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AllGroups {\n    groupCollection {\n      edges {\n        node {\n          nodeId\n          id\n          display_name\n          is_private\n          postCollection(\n            first: 2\n            filter: { has_uploaded_photo: { eq: true } }\n          ) {\n            edges {\n              node {\n                nodeId\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AllGroups {\n    groupCollection {\n      edges {\n        node {\n          nodeId\n          id\n          display_name\n          is_private\n          postCollection(\n            first: 2\n            filter: { has_uploaded_photo: { eq: true } }\n          ) {\n            edges {\n              node {\n                nodeId\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GroupById(\n    $id: UUID!\n    $withPosts: Boolean = false\n    $withCategories: Boolean = false\n  ) {\n    groupCollection(filter: { id: { eq: $id } }) {\n      edges {\n        node {\n          id\n          display_name\n          description\n          is_private\n          postCollection(\n            filter: { has_uploaded_photo: { eq: true } }\n            orderBy: [{ created_at: DescNullsLast }]\n          ) @include(if: $withPosts) {\n            edges {\n              node {\n                nodeId\n                created_at\n                id\n              }\n            }\n          }\n          group_categoryCollection @include(if: $withCategories) {\n            edges {\n              node {\n                nodeId\n                id\n                category {\n                  id\n                  display_name\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GroupById(\n    $id: UUID!\n    $withPosts: Boolean = false\n    $withCategories: Boolean = false\n  ) {\n    groupCollection(filter: { id: { eq: $id } }) {\n      edges {\n        node {\n          id\n          display_name\n          description\n          is_private\n          postCollection(\n            filter: { has_uploaded_photo: { eq: true } }\n            orderBy: [{ created_at: DescNullsLast }]\n          ) @include(if: $withPosts) {\n            edges {\n              node {\n                nodeId\n                created_at\n                id\n              }\n            }\n          }\n          group_categoryCollection @include(if: $withCategories) {\n            edges {\n              node {\n                nodeId\n                id\n                category {\n                  id\n                  display_name\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GroupPostsByCategoryIds(\n    $id: UUID!\n    $filteredCategoryIds: [UUID!] = []\n  ) {\n    filteredPosts: groupPostsByCategoryIds(\n      groupid: $id\n      categoryids: $filteredCategoryIds\n      orderBy: [{ created_at: DescNullsLast }]\n    ) {\n      edges {\n        node {\n          nodeId\n          created_at\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GroupPostsByCategoryIds(\n    $id: UUID!\n    $filteredCategoryIds: [UUID!] = []\n  ) {\n    filteredPosts: groupPostsByCategoryIds(\n      groupid: $id\n      categoryids: $filteredCategoryIds\n      orderBy: [{ created_at: DescNullsLast }]\n    ) {\n      edges {\n        node {\n          nodeId\n          created_at\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PostById($id: UUID!, $withCategories: Boolean = false) {\n    postCollection(\n      filter: {\n        and: [{ id: { eq: $id } }, { has_uploaded_photo: { eq: true } }]\n      }\n    ) {\n      edges {\n        node {\n          nodeId\n          id\n          file_name\n          group_id\n          meta_width\n          meta_height\n          photo_type\n          description\n          post_categoryCollection @include(if: $withCategories) {\n            edges {\n              node {\n                id\n                category {\n                  display_name\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PostById($id: UUID!, $withCategories: Boolean = false) {\n    postCollection(\n      filter: {\n        and: [{ id: { eq: $id } }, { has_uploaded_photo: { eq: true } }]\n      }\n    ) {\n      edges {\n        node {\n          nodeId\n          id\n          file_name\n          group_id\n          meta_width\n          meta_height\n          photo_type\n          description\n          post_categoryCollection @include(if: $withCategories) {\n            edges {\n              node {\n                id\n                category {\n                  display_name\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;