import { AspectRatio, Box } from "@radix-ui/themes";
import { isPostImageLoaded, usePostImageStore } from "hooks/usePostImage";

interface PostTileProps {
  postId: string;
}

export function PostTileSquare({ postId }: PostTileProps) {
  const { postImage } = usePostImageStore(postId);

  return (
    <AspectRatio ratio={1 / 1}>
      <Box width="100%" height="100%" asChild>
        {isPostImageLoaded(postImage) && (
          <img
            src={postImage.signedUrl}
            style={{
              objectFit: "cover",
              borderRadius: "var(--radius-2)",
            }}
          />
        )}
      </Box>
    </AspectRatio>
  );
}

export function PostTileLoading() {
  return (
    <AspectRatio ratio={1 / 1}>
      <div
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "var(--radius-2)",
        }}
      />
    </AspectRatio>
  );
}
