import { Button, Flex } from "@radix-ui/themes";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { Container } from "ui/Container";
import { RouterLink } from "ui/RouterLink/RouterLink";
import { RouteDefinition } from "util/routes";

import { createBrowserClient } from "util/supabase";

export function PrimaryNavigation() {
  return (
    <Container py="2">
      <Flex justify="between" align="center">
        <Flex gap="4">
          <RouterLink to={RouteDefinition.HOME} weight="bold">
            📸 VotoBubble
          </RouterLink>
          <RouterLink to={RouteDefinition.GROUPS}>Groups</RouterLink>
        </Flex>
        <Flex>
          <Auth />
        </Flex>
      </Flex>
    </Container>
  );
}

function Auth() {
  const { session } = useAuth();

  const handleSignOut = useCallback(() => {
    const supabase = createBrowserClient();
    supabase.auth.signOut();
  }, []);

  if (!session) {
    return (
      <Button asChild size="2" variant="outline">
        <Link to={RouteDefinition.AUTH_REGISTER}>Sign up</Link>
      </Button>
    );
  }

  return (
    <Button onClick={handleSignOut} variant="soft" size="2">
      Sign out
    </Button>
  );
}
