import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./util/apolloClient";
import { AuthProvider } from "providers/AuthProvider/AuthProvider";
import * as Dialog from "@radix-ui/react-dialog";
import { Theme } from "@radix-ui/themes";
import { ThemeProvider as NextThemeProvider } from "next-themes";

import "@radix-ui/themes/styles.css";
import "global.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const ThemeProvider = NextThemeProvider as any;

root.render(
  <React.StrictMode>
    <AuthProvider>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider attribute="class">
          <Theme
            accentColor="green"
            style={{
              height: "100%",
              backgroundColor: "var(--blue-1)",
            }}
          >
            <RadixRoots>
              <App />
            </RadixRoots>
          </Theme>
        </ThemeProvider>
      </ApolloProvider>
    </AuthProvider>
  </React.StrictMode>
);

function RadixRoots({ children }: PropsWithChildren) {
  return <Dialog.Root>{children}</Dialog.Root>;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
