import { useSuspenseQuery } from "@apollo/client";
import { Bucket } from "util/supabase/types";
import { QUERY_POST_BY_ID } from "queries/queryPostById";
import { useEffect, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";
import { isDefined } from "pages/Group/utils/isDefined";
import { createBrowserClient } from "util/supabase";

type PostImageLoading = { loading: true };
type PostImageError = { loading: false; error: string };
type PostImageSuccess = {
  loading: false;
  signedUrl: string;
  expiresAt: number;
};

type PostImage = PostImageLoading | PostImageError | PostImageSuccess;

export function isPostImageLoaded(image: any): image is PostImageSuccess {
  if (!image || typeof image !== "object") return false;

  if (!!image.loading || !!image.error) return false;

  return true;
}

function getImagePath(postId: string, fileName: string) {
  return `posts/${postId}/${fileName}`;
}

function getPostImageKey(
  path: string,
  options: ReturnType<typeof generateDynamicOptions>
) {
  return [
    Bucket.PHOTOGRAPHY,
    path,
    options.transform.width,
    options.transform.height,
    options.transform.quality,
  ]
    .filter(isDefined)
    .join(":");
}

const POST_STORE_LOCAL_KEY = "POST_STORE_LOCAL_KEY";
function getLocalStorageValue(postImageKey: string) {
  const value = window.localStorage.getItem(POST_STORE_LOCAL_KEY);

  if (!value) return undefined;
  try {
    const parsed = JSON.parse(value);
    return parsed[postImageKey];
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

type ImageStore = Record<string, PostImage>;

interface Options {
  withCategories?: boolean;
}

export function usePostImageStore(
  postId?: string,
  options: Options = { withCategories: false }
) {
  const [imageStore, setImageStore] = useLocalStorage<ImageStore>(
    POST_STORE_LOCAL_KEY,
    {}
  );

  const { data } = useSuspenseQuery(QUERY_POST_BY_ID, {
    variables: { id: postId, withCategories: options.withCategories },
  });

  useEffect(() => {
    async function fetchImage() {
      const post = data?.postCollection?.edges[0].node;
      if (!post) return;

      const requestOptions = generateDynamicOptions(
        +post.meta_width,
        +post.meta_height
      );

      const path = getImagePath(post.id, post.file_name);

      const key = getPostImageKey(path, requestOptions);

      const existingImage = getLocalStorageValue(key);

      const imageIsStillValid =
        isPostImageLoaded(existingImage) &&
        existingImage.expiresAt > Date.now();

      if (imageIsStillValid) return;

      const now = Date.now();
      const bucket = Bucket.PHOTOGRAPHY;

      const TimeToLiveMs = 60 * 60 * 24 * 1000;
      setImageStore((v) => ({
        ...v,
        [key]: {
          loading: true,
        },
      }));

      const signedUrlResponse = await createBrowserClient()
        .storage.from(bucket)
        .createSignedUrl(path, TimeToLiveMs / 1000, requestOptions);

      if (signedUrlResponse.data?.signedUrl) {
        setImageStore((v) => ({
          ...v,
          [key]: {
            loading: false,
            signedUrl: signedUrlResponse.data.signedUrl,
            expiresAt: now + TimeToLiveMs,
          },
        }));
      } else {
        setImageStore((v) => ({
          ...v,
          [key]: { loading: false, error: "Failed to fetch signed URL" },
        }));
      }
    }

    fetchImage();
  }, [data, setImageStore]);

  return useMemo(() => {
    const post = data?.postCollection?.edges[0].node;

    if (!post) {
      const postImage: PostImage = { loading: true };
      return { post, data, postImage };
    }

    const requestOptions = generateDynamicOptions(
      +post.meta_width,
      +post.meta_height
    );

    const path = getImagePath(post.id, post.file_name);

    return {
      post,
      data,
      postImage: imageStore[getPostImageKey(path, requestOptions)],
      categories: post.post_categoryCollection?.edges.map(
        (category) => category.node.category
      ),
    };
  }, [imageStore, data]);
}

const MAX_WIDTH = 1230;

export function generateDynamicOptions(
  width: number,
  height: number,
  maxWidth: number = MAX_WIDTH,
  quality: number = 60
) {
  function generateDimensions() {
    if (width <= maxWidth) {
      return { w: width, h: height };
    } else {
      const ratio = maxWidth / width;
      return { w: maxWidth, h: Math.round(height * ratio) };
    }
  }

  const { w, h } = generateDimensions();

  return {
    transform: {
      width: w,
      height: h,
      quality,
    },
  };
}
