import { useCallback, useReducer } from "react";
import { createBrowserClient } from "util/supabase";
import { z } from "zod";
import apolloClient from "util/apolloClient";
import { Photo_Type } from "gql/graphql";
import { formReducer, initialState } from "./postState";
import { QUERY_GROUP_BY_ID } from "../../../queries/queryGroupById";
import { GET_ALL_GROUPS } from "../utils/getAllGroups";
import { QUERY_GROUP_POSTS_BY_CATEGORY_IDS } from "queries/queryGroupPostsByCategoryIds";

export const IMAGE_SIZE_LIMIT = 10 * 1024 * 1024; // 10MB
const ACCEPTED_FILE_TYPES = ["image/jpg", "image/jpeg"];

export const PostFormSchema = z.object({
  file: z
    .instanceof(File, { message: "Invalid file." })
    .refine((file) => file instanceof File, { message: "Invalid file." })
    .refine((file) => !file || (!!file && file.size <= IMAGE_SIZE_LIMIT), {
      message: "The profile picture must be a maximum of 10MB.",
    })
    .refine((file) => !file || (!!file && file.type?.startsWith("image")), {
      message: "Only images are allowed to be sent.",
    })
    .refine((file) => ACCEPTED_FILE_TYPES.includes(file.type), {
      message: "File must be a JPG or JPEG.",
    }),
  description: z.string({ message: "Foo" }).optional(),
  categoryIds: z.array(z.string()).optional(),
  photoType: z.nativeEnum(Photo_Type, { message: "Photo type is required." }),
  cameraMake: z.string().optional(),
  cameraModel: z.string().optional(),
  width: z.number(),
  height: z.number(),
});

export function useCreatePost(groupId: string) {
  const [postState, dispatchPostState] = useReducer(formReducer, initialState);

  const submitPost = useCallback(
    async (newPost: unknown) => {
      console.log(newPost);

      dispatchPostState({ type: "SET_LOADING", payload: true });
      dispatchPostState({ type: "SET_VALIDATION_ISSUES", payload: undefined });
      dispatchPostState({ type: "SET_MUTATION_ISSUES", payload: undefined });
      const client = createBrowserClient();

      try {
        const post = PostFormSchema.parse(newPost);
        const formData = new FormData();

        formData.append("file", post.file, post.file.name);
        formData.append("groupId", groupId);
        if (post.description) formData.append("description", post.description);
        if (post.photoType) formData.append("photoType", post.photoType);
        if (post.cameraMake) formData.append("cameraMake", post.cameraMake);
        if (post.cameraModel) formData.append("cameraModel", post.cameraModel);
        if (post.width) formData.append("width", `${post.width}`);
        if (post.height) formData.append("height", `${post.height}`);
        if (post.categoryIds)
          formData.append("categoryIds", post.categoryIds.join(","));

        const { data, error } = await client.functions.invoke(
          "handle-post-create",
          {
            method: "POST",
            body: formData,
          }
        );

        if (error) throw new Error(error);

        await apolloClient.refetchQueries({
          include: [
            QUERY_GROUP_BY_ID,
            GET_ALL_GROUPS,
            QUERY_GROUP_POSTS_BY_CATEGORY_IDS,
          ],
        });

        dispatchPostState({ type: "SET_PHOTO_ID", payload: data.post.id });
      } catch (error) {
        dispatchPostState({ type: "SET_LOADING", payload: false });
        if (error instanceof z.ZodError) {
          dispatchPostState({
            type: "SET_VALIDATION_ISSUES",
            payload: error.issues,
          });
        } else if (error instanceof Error) {
          dispatchPostState({
            type: "SET_MUTATION_ISSUES",
            payload:
              "We had some trouble creating your post. Please try again or come back later.",
          });
          console.error(error);
        } else {
          dispatchPostState({
            type: "SET_MUTATION_ISSUES",
            payload: "An unknown error occurred.",
          });
        }
      }
    },
    [dispatchPostState, groupId]
  );

  return { submitPost, postState, dispatchPostState };
}
