import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useSignInForm, useSignUpForm } from "hooks/auth";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Container } from "ui/Container";
import { RouteDefinition } from "util/routes";

interface AuthProps {
  state: "LOGIN" | "SIGN_UP";
}

export function Auth({ state }: AuthProps) {
  const navigate = useNavigate();

  return (
    <Container height="100%">
      <Grid
        columns={{ initial: "1", md: "2" }}
        gap="6"
        height="100%"
        width="100%"
        align="center"
        justify="center"
      >
        <Box
          flexGrow="1"
          height="100%"
          display={{ initial: "none", md: "block" }}
          style={{
            position: "relative",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "1rem",
            backgroundImage: "url(/auth-splash.jpg)",
          }}
        >
          <Box
            style={{
              position: "absolute",
              bottom: "var(--space-2)",
              left: "var(--space-2)",
            }}
          >
            <Text style={{ color: "#fff" }} size="1">
              Photo by{" "}
              <a
                style={{ color: "#fff" }}
                href="https://unsplash.com/@ollie_jordan?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
              >
                Ollie Jordan
              </a>{" "}
              on{" "}
              <a
                style={{ color: "#fff" }}
                href="https://unsplash.com/photos/brown-concrete-building-under-blue-sky-during-daytime-GfnNOLt-MpI?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
              >
                Unsplash
              </a>
            </Text>
          </Box>
        </Box>

        <Flex justify="center">
          <Flex direction="column" maxWidth="320px" width="100%" gap="4">
            <Flex justify="center" gap="2">
              <Button
                variant={state === "LOGIN" ? "solid" : "outline"}
                color={state === "LOGIN" ? undefined : "gray"}
                onClick={() => navigate(RouteDefinition.AUTH_LOGIN)}
              >
                Login
              </Button>
              <Button
                variant={state === "SIGN_UP" ? "solid" : "outline"}
                color={state === "SIGN_UP" ? undefined : "gray"}
                onClick={() => navigate(RouteDefinition.AUTH_REGISTER)}
              >
                Register
              </Button>
            </Flex>
            {state === "LOGIN" ? <SignIn /> : <SignUp />}
          </Flex>
        </Flex>
      </Grid>
    </Container>
  );
}

function SignIn() {
  const { handleSubmit, loading } = useSignInForm();

  const isDisabled = loading;

  return (
    <Flex direction="column" gap="3">
      <form onSubmit={handleSubmit}>
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="2">
            <Flex direction="column" gap="1">
              <Text as="label" htmlFor="email" size="2">
                Email
              </Text>
              <TextField.Root
                size="3"
                id="email"
                name="email"
                type="email"
                placeholder="billie@votobubble.com"
              />
            </Flex>
            <Flex direction="column" gap="1">
              <Text as="label" htmlFor="password" size="2">
                Password
              </Text>
              <TextField.Root
                size="3"
                id="password"
                name="password"
                type="password"
              />
            </Flex>
          </Flex>
          <Button disabled={isDisabled} size="3" type="submit">
            Sign in
          </Button>
        </Flex>
      </form>
    </Flex>
  );
}

function SignUp() {
  const { handleSubmit, loading, submitted } = useSignUpForm();

  const isDisabled = loading;

  return (
    <>
      <Flex direction="column" gap="3">
        {submitted ? (
          <StyledSubmitted>
            <Heading as="h1">Almost done...</Heading>
            <Text as="p">
              We've sent you an email to confirm your account. Please check your
              spam folder.
            </Text>
          </StyledSubmitted>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <Flex direction="column" gap="4">
                <Flex direction="column" gap="2">
                  <Flex direction="column" gap="1">
                    <Text as="label" htmlFor="email" size="2">
                      Email
                    </Text>
                    <TextField.Root
                      size="3"
                      id="email"
                      name="email"
                      type="email"
                      placeholder="leonie@votobubble.com"
                    />
                  </Flex>
                  <Flex direction="column" gap="1">
                    <Text as="label" htmlFor="password" size="2">
                      Password
                    </Text>
                    <TextField.Root
                      size="3"
                      id="password"
                      name="password"
                      type="password"
                    />
                  </Flex>
                </Flex>
                <Button disabled={isDisabled} size="3" type="submit">
                  Sign up
                </Button>
              </Flex>
            </form>
          </>
        )}
      </Flex>
    </>
  );
}

const StyledSubmitted = styled(Flex)`
  background: var(--blue-3);
  border: 1px solid var(--blue-8);
  color: var(--blue-11);

  flex-direction: column;
  justify-content: center;
  padding: var(--space-4);
  border-radius: var(--radius-4);
  gap: var(--space-2);
`;
