import { graphql } from "gql";

export const QUERY_GROUP_BY_ID = graphql(/* GraphQL */ `
  query GroupById(
    $id: UUID!
    $withPosts: Boolean = false
    $withCategories: Boolean = false
  ) {
    groupCollection(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          display_name
          description
          is_private
          postCollection(
            filter: { has_uploaded_photo: { eq: true } }
            orderBy: [{ created_at: DescNullsLast }]
          ) @include(if: $withPosts) {
            edges {
              node {
                nodeId
                created_at
                id
              }
            }
          }
          group_categoryCollection @include(if: $withCategories) {
            edges {
              node {
                nodeId
                id
                category {
                  id
                  display_name
                }
              }
            }
          }
        }
      }
    }
  }
`);
