import { Box, Dialog, IconButton } from "@radix-ui/themes";
import { useCallback } from "react";
import { useNavigate, useParams, generatePath, Outlet } from "react-router-dom";
import styled from "styled-components";
import { RouteDefinition } from "util/routes";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import { twSize } from "util/twSize";

interface SelectedPostOutletProps {
  asModal: boolean;
}

export function SelectedPostOutlet({ asModal }: SelectedPostOutletProps) {
  const { postId } = useParams();

  if (!postId) return null;

  return asModal ? <ModalOutlet /> : <InlineOut />;
}

export function InlineOut() {
  return (
    <StyledInlinePostContainer>
      <Outlet />
    </StyledInlinePostContainer>
  );
}

export function ModalOutlet() {
  const navigation = useNavigate();

  const { groupId } = useParams();

  const handleClearSelectedPost = useCallback(() => {
    if (!groupId) throw new Error("Group ID not found");

    navigation(generatePath(RouteDefinition.GROUP_BY_ID, { groupId }));
  }, [groupId, navigation]);

  return (
    <Dialog.Root open={true} onOpenChange={handleClearSelectedPost}>
      <StyledDialogContainer>
        <StyledDialogClose>
          <IconButton onClick={handleClearSelectedPost} variant="soft">
            <Box asChild width={twSize(4)} height={twSize(4)}>
              <XMarkIcon />
            </Box>
          </IconButton>
        </StyledDialogClose>

        <Outlet />
      </StyledDialogContainer>
    </Dialog.Root>
  );
}

const StyledDialogClose = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: var(--space-2);
`;

const StyledInlinePostContainer = styled(Box)`
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
`;

const StyledDialogContainer = styled(Dialog.Content)`
  position: fixed !important;
  display: flex;
  flex-direction: column;
  inset: 0;
  animation: none;
  border-radius: 0;

  width: 100%;
  max-width: 100%;
  padding: 0;
  height: 100%;
  max-height: 100%;
`;
