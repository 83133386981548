import { Text } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import { Container } from "ui/Container";
import { Hero } from "ui/Hero";
import { RouteDefinition } from "util/routes";

export function MissingGroup() {
  return (
    <Container>
      <Hero title="Missing group">
        <Text as="p">
          We can't find that group. Check you're logged in or{" "}
          <Link to={RouteDefinition.HOME}>go home.</Link>
        </Text>
      </Hero>
    </Container>
  );
}
