import {
  useRef,
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  useMemo,
  PropsWithChildren,
} from "react";
import { SingleImageUploader } from "ui/SingleImageUploader";
import { Link, generatePath, useParams } from "react-router-dom";
import { RouteDefinition } from "util/routes";
import { useSuspenseQuery } from "@apollo/client";
import { QUERY_GROUP_BY_ID } from "../../../queries/queryGroupById";
import { MissingGroup } from "../ui/MissingGroup";
import { ErrorBoundary } from "react-error-boundary";
import { IMAGE_SIZE_LIMIT, useCreatePost } from "./useCreatePost";
import { Photo_Type } from "gql/graphql";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  RadioGroup,
  Spinner,
  Text,
  TextArea,
} from "@radix-ui/themes";
import { Container } from "ui/Container";
import { IssueList } from "ui/IssueList";
import { twSize } from "util/twSize";
import { Upload } from "ui/SingleImageUploader/SingleImageUploader";
import { RouterLink } from "ui/RouterLink";
import styled from "styled-components";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";

export function NewPostPage() {
  return (
    <ErrorBoundary fallback={<MissingGroup />}>
      <Suspense fallback={<NewPostPageLoading />}>
        <NewPost />
      </Suspense>
    </ErrorBoundary>
  );
}

function NewPostPageLoading() {
  return (
    <Flex height="100%" width="100%" justify="center" align="center">
      <Spinner size="3" />
    </Flex>
  );
}

function NewPost() {
  const { groupId } = useParams();
  useSuspenseQuery(QUERY_GROUP_BY_ID, {
    variables: { id: groupId },
  });

  if (!groupId) throw new Error("Missing group ID.");

  return (
    <Container>
      <div>
        <Button asChild variant="soft" color="gray">
          <Link
            to={generatePath(RouteDefinition.GROUP_BY_ID, {
              groupId: groupId!,
            })}
          >
            Cancel
          </Link>
        </Button>
      </div>

      <PhotographPostForm groupId={groupId} />
    </Container>
  );
}

interface PhotographPostFormProps {
  groupId: string;
}

function usePostForm(groupId: string) {
  const { postState, submitPost, dispatchPostState } = useCreatePost(groupId);

  const handlePostSubmit = useCallback(
    (formEvent: React.FormEvent<HTMLFormElement>) => {
      formEvent.preventDefault();
      submitPost(postState);
    },
    [submitPost, postState]
  );

  const handleImageSubmit = useCallback(
    (upload: Upload) => {
      dispatchPostState({
        type: "SET_FILE_META",
        payload: { height: upload.meta.Height, width: upload.meta.Width },
      });
      dispatchPostState({ type: "SET_FILE", payload: upload.file });
    },
    [dispatchPostState]
  );

  return { postState, dispatchPostState, handlePostSubmit, handleImageSubmit };
}

function useGroupById(groupId: string) {
  const queryResponse = useSuspenseQuery(QUERY_GROUP_BY_ID, {
    variables: { id: groupId, withCategories: true },
  });

  return useMemo(() => {
    const group = queryResponse.data.groupCollection?.edges[0]?.node;
    return {
      group,
      categories: group?.group_categoryCollection?.edges.map(
        (edge) => edge.node.category
      ),
      ...queryResponse,
    };
  }, [queryResponse]);
}

function PhotographPostForm({ groupId }: PhotographPostFormProps) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { group, categories } = useGroupById(groupId);
  if (!group) throw new Error("Group not found");

  const partTwoRef = useRef<HTMLDivElement>(null);
  const { postState, handleImageSubmit, handlePostSubmit, dispatchPostState } =
    usePostForm(groupId);
  const imagePreviewRef = useRef<HTMLImageElement>(null);

  const completed = postState.postId;

  // TODO: Add disabled
  const disabled = postState.loading;

  const showPartOne = !completed && true;
  const showPartTwo = !completed && postState.file && imageLoaded;

  useLayoutEffect(() => {
    if (!showPartTwo || !partTwoRef.current) return;
    const timeout = setTimeout(() => {
      partTwoRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);

    return () => clearTimeout(timeout);
  }, [showPartTwo]);

  useEffect(() => {
    if (imagePreviewRef.current) {
      if (postState.file) {
        imagePreviewRef.current.src = URL.createObjectURL(postState.file);
      } else {
        imagePreviewRef.current.src = "";
      }

      imagePreviewRef.current.onload = () => {
        setImageLoaded(true);
      };
    }
  }, [postState.file]);

  const handleCategoryToggle = useCallback(
    (categoryId: string) => {
      dispatchPostState({ type: "TOGGLE_CATEGORY", payload: categoryId });
    },
    [dispatchPostState]
  );

  return (
    <Flex width="100%" justify="center" py="6">
      <Grid
        columns={{ initial: "1" }}
        gap="8"
        px={{ sm: "80px" }}
        width="100%"
        maxWidth="750px"
      >
        <Flex direction="column" gap="2">
          <Heading as="h1" size="8">
            New Post
          </Heading>
          <Text>
            After a few steps, your photo will be uploaded into{" "}
            <RouterLink
              to={generatePath(RouteDefinition.GROUP_BY_ID, { groupId })}
            >
              {group.display_name}
            </RouterLink>
            .
          </Text>
        </Flex>

        {showPartOne && (
          <Flex direction="column" gap="4" pb="30px" id="step-one">
            <Heading as="h2">Upload your photo.</Heading>

            <Flex
              align="center"
              justify="center"
              style={{
                borderRadius: "var(--radius-4)",
                backgroundColor: "var(--mauve-2)",
                overflow: "hidden",
              }}
            >
              <Box
                position="relative"
                style={{ display: !postState.file ? "none" : "block" }}
              >
                <Button
                  color="crimson"
                  size="1"
                  style={{
                    position: "absolute",
                    top: "var(--space-2)",
                    right: "var(--space-2)",
                  }}
                  onClick={() => {
                    setImageLoaded(false);
                    dispatchPostState({ type: "CLEAR_FILE" });
                  }}
                >
                  Clear
                </Button>
                <Box
                  asChild
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: "contain",
                  }}
                >
                  <img ref={imagePreviewRef} alt="Preview of your photograph" />
                </Box>
              </Box>

              {!postState.file && (
                <Box p="4">
                  <SingleImageUploader
                    fileSizeLimit={IMAGE_SIZE_LIMIT}
                    onFileUpload={handleImageSubmit}
                  />
                </Box>
              )}
            </Flex>
          </Flex>
        )}

        {showPartTwo && (
          <StyledPartTwo
            asChild
            direction="column"
            justify="between"
            pb="40%"
            gap="5"
            ref={partTwoRef}
          >
            <form onSubmit={handlePostSubmit}>
              <Heading as="h2">Describe your photo.</Heading>

              <Flex direction="column" gap="2">
                <Text
                  as="label"
                  size="3"
                  htmlFor="post_description"
                  weight="bold"
                >
                  Description
                </Text>
                <TextArea
                  value={postState.description}
                  onChange={(e) =>
                    dispatchPostState({
                      type: "SET_DESCRIPTION",
                      payload: e.target.value,
                    })
                  }
                  size="3"
                  id="post_description"
                  name="post_description"
                  placeholder="Tell us about your photo."
                  maxLength={200}
                  style={{ maxHeight: "200px" }}
                />
              </Flex>

              <Flex direction="column" gap="2">
                <Text
                  as="label"
                  size="3"
                  htmlFor="post_description"
                  weight="bold"
                >
                  Categories
                </Text>
                <Flex gap="2" wrap="wrap">
                  {categories?.map((category) => {
                    const isChecked = !!postState.categoryIds?.includes(
                      category?.id
                    );

                    return (
                      <CategoryCheckbox
                        key={category?.id}
                        checked={isChecked}
                        id={category?.id}
                        onChange={handleCategoryToggle}
                      >
                        {category?.display_name}
                      </CategoryCheckbox>
                    );
                  })}
                </Flex>
              </Flex>

              <RadioGroup.Root
                defaultValue={postState.photoType}
                name="example"
                value={postState.photoType}
                size="3"
              >
                <Flex direction="column" gap="3">
                  <Text as="label" size="3" weight="bold">
                    Type of photo
                  </Text>
                  <RadioGroup.Item
                    value={Photo_Type.Digital}
                    onClick={() =>
                      dispatchPostState({
                        type: "SET_PHOTO_TYPE",
                        payload: Photo_Type.Digital,
                      })
                    }
                  >
                    Digital
                  </RadioGroup.Item>
                  <RadioGroup.Item
                    value={Photo_Type.Film}
                    onClick={() =>
                      dispatchPostState({
                        type: "SET_PHOTO_TYPE",
                        payload: Photo_Type.Film,
                      })
                    }
                  >
                    Film
                  </RadioGroup.Item>
                </Flex>
              </RadioGroup.Root>

              <Button type="submit" disabled={disabled}>
                Submit
              </Button>

              {postState.submissionIssue && (
                <Box
                  p="2"
                  style={{
                    background: "var(--red-3)",
                    border: "1px solid var(--red-8)",
                    borderRadius: twSize(2),
                    color: "var(--red-10)",
                  }}
                >
                  {postState.submissionIssue}
                </Box>
              )}

              {postState.validationIssues?.length && (
                <IssueList issueList={postState.validationIssues} />
              )}
            </form>
          </StyledPartTwo>
        )}

        {completed && (
          <StyledCompleted>
            <Box asChild height={twSize(10)} width={twSize(10)}>
              <CheckCircleIcon />
            </Box>
            <Heading as="h1">You post has been created.</Heading>
            <Text as="p">You post has been created.</Text>
            <RouterLink
              to={generatePath(RouteDefinition.GROUP_POST_BY_ID, {
                groupId,
                postId: postState.postId!,
              })}
            >
              View your post
            </RouterLink>
          </StyledCompleted>
        )}
      </Grid>
    </Flex>
  );
}

interface CategoryCheckboxProps extends PropsWithChildren {
  id: string;
  checked: boolean;
  onChange(id: string): void;
}

function CategoryCheckbox({
  id,
  children,
  checked,
  onChange,
}: CategoryCheckboxProps) {
  return (
    <StyledCheckboxButton asChild $checked={checked}>
      <label htmlFor={`checkbox-${id}`}>
        <Checkbox
          size="1"
          id={`checkbox-${id}`}
          checked={checked}
          onCheckedChange={() => onChange(id)}
        />
        {children}
      </label>
    </StyledCheckboxButton>
  );
}

const StyledCheckboxButton = styled(Box)<{ $checked: boolean }>`
  display: flex;
  align-items: center;
  gap: var(--space-2);
  border-radius: var(--radius-2);
  padding: var(--space-2) var(--space-3);
  background: ${(p) => (p.$checked ? "var(--accent-3)" : "var(--gray-3)")};
  user-select: none;
`;

const StyledCompleted = styled(Flex)`
  background: var(--green-3);
  border: 1px solid var(--green-8);
  color: var(--green-11);

  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--space-4);
  border-radius: var(--radius-4);
  gap: var(--space-2);
`;

const StyledPartTwo = styled(Flex)`
  animation: 250ms ease-out 0s 1 bumpIn;
`;
