import { Suspense } from "react";
import { useSuspenseQuery } from "@apollo/client";
import { Link, generatePath } from "react-router-dom";
import { Hero } from "ui/Hero";

import { PostTileSquare } from "ui/PostTile/PostTile";
import { AspectRatio, Flex, Grid, Heading, Text } from "@radix-ui/themes";
import { RouteDefinition } from "util/routes";
import { Container } from "ui/Container";
import { twSize } from "util/twSize";
import { GET_ALL_GROUPS } from "pages/Group/utils/getAllGroups";

export function GroupsPage() {
  return (
    <Container>
      <Hero title="Groups">
        <Text as="p" wrap="wrap">
          Discover and join the various groups to showcase your photography
          skills and engage with a community of passionate photographers.
        </Text>
      </Hero>

      <Suspense fallback={<div>Loading...</div>}>
        <GroupsList />
      </Suspense>
    </Container>
  );
}

export function GroupsList() {
  const { data } = useSuspenseQuery(GET_ALL_GROUPS);

  const groups = data.groupCollection?.edges;

  return (
    <Grid columns={{ initial: "1", sm: "3" }} gap="6">
      {groups?.map(({ node: group }) => {
        const groupPosts = group.postCollection?.edges;

        return (
          <Flex
            key={group.id}
            direction="column"
            gap="2"
            style={{ position: "relative" }}
          >
            <Grid columns="3" gap="2">
              {groupPosts?.map(({ node: post }) => (
                <PostTileSquare key={post.id} postId={post.id} />
              ))}

              {!(groupPosts?.length || 0) && (
                <AspectRatio ratio={1 / 1}>
                  <Flex
                    width="100%"
                    height="100%"
                    align="center"
                    justify="center"
                    style={{
                      textAlign: "center",
                      borderRadius: twSize(2),
                      background: "var(--gray-3)",
                    }}
                  >
                    <Text weight="bold" style={{ color: "var(--gray-8)" }}>
                      No
                      <br /> Photos
                    </Text>
                  </Flex>
                </AspectRatio>
              )}
            </Grid>

            <Heading size="5" weight="bold">
              {group.display_name}
            </Heading>

            <Link
              to={generatePath(RouteDefinition.GROUP_BY_ID, {
                groupId: group.id,
              })}
              style={{ position: "absolute", inset: 0 }}
            />
          </Flex>
        );
      })}
    </Grid>
  );
}
