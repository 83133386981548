import { graphql } from "gql";

export const QUERY_POST_BY_ID = graphql(/* GraphQL */ `
  query PostById($id: UUID!, $withCategories: Boolean = false) {
    postCollection(
      filter: {
        and: [{ id: { eq: $id } }, { has_uploaded_photo: { eq: true } }]
      }
    ) {
      edges {
        node {
          nodeId
          id
          file_name
          group_id
          meta_width
          meta_height
          photo_type
          description
          post_categoryCollection @include(if: $withCategories) {
            edges {
              node {
                id
                category {
                  display_name
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`);
