import { Box, AspectRatio, Skeleton } from "@radix-ui/themes";
import { isPostImageLoaded, usePostImageStore } from "hooks/usePostImage";

interface PostImageProps {
  postId: string;
}

export function PostImage({ postId }: PostImageProps) {
  const { post, postImage } = usePostImageStore(postId);

  // TODO: something is wrong
  if (!post) return null;

  return (
    <Box>
      <AspectRatio ratio={+post.meta_width / +post.meta_height}>
        {isPostImageLoaded(postImage) ? (
          <Box asChild height="100%" width="100%">
            <img src={postImage.signedUrl} />
          </Box>
        ) : (
          <Skeleton width="100%" height="100%" />
        )}
      </AspectRatio>
    </Box>
  );
}
