import { generatePath, useParams } from "react-router-dom";
import { Badge, Box, DataList, Flex, Text } from "@radix-ui/themes";

import styled from "styled-components";

import { Container } from "ui/Container";
import { useSuspenseQuery } from "@apollo/client";
import { QUERY_GROUP_BY_ID } from "queries/queryGroupById";
import { RouterLink } from "ui/RouterLink";
import { RouteDefinition } from "util/routes";
import { Suspense } from "react";
import { isPostImageLoaded, usePostImageStore } from "hooks/usePostImage";

export function PostPage() {
  return <Post />;
}

function Post() {
  const { postId } = useParams();
  const { post, postImage, categories } = usePostImageStore(postId, {
    withCategories: true,
  });

  if (!post) throw new Error("Invalid page URL");

  return (
    <Flex direction="column" gap="4">
      <StyledImageTwo asChild>
        {isPostImageLoaded(postImage) && <img src={postImage.signedUrl} />}
      </StyledImageTwo>
      <Container>
        <DataList.Root>
          {post.description && (
            <DataList.Item>
              <DataList.Label>Description</DataList.Label>
              <DataList.Value>{post.description}</DataList.Value>
            </DataList.Item>
          )}
          {post.photo_type && (
            <DataList.Item>
              <DataList.Label>Photo type</DataList.Label>
              <DataList.Value>{post.photo_type}</DataList.Value>
            </DataList.Item>
          )}
          {categories && (
            <DataList.Item>
              <DataList.Label>Categories</DataList.Label>
              <DataList.Value>
                <Flex gap="2">
                  {categories.map((category) => (
                    <Badge key={category.id}>{category.display_name}</Badge>
                  ))}
                </Flex>
              </DataList.Value>
            </DataList.Item>
          )}
          <DataList.Item>
            <DataList.Label>Group</DataList.Label>
            <DataList.Value>
              <Suspense fallback={<Text>Loading</Text>}>
                <GroupLink groupId={post.group_id} />
              </Suspense>
            </DataList.Value>
          </DataList.Item>
        </DataList.Root>
      </Container>
    </Flex>
  );
}

interface GroupLinkProps {
  groupId: string;
}

function GroupLink({ groupId }: GroupLinkProps) {
  const { data } = useSuspenseQuery(QUERY_GROUP_BY_ID, {
    variables: { id: groupId },
  });
  const group = data.groupCollection?.edges[0]?.node;

  if (!group) return <Text>Private Group</Text>;

  return (
    <RouterLink to={generatePath(RouteDefinition.GROUP_BY_ID, { groupId })}>
      {group.display_name}
    </RouterLink>
  );
}

const StyledImageTwo = styled(Box)`
  width: 100%;
  max-height: 80vh;
  max-height: 80svh;
  object-fit: contain;
`;
